import env from "#app/env";
import { SessionStorageKeys, useSessionStorage } from "#app/lib/useSessionStorage";
import { useSearchParams } from "next/navigation";

export function useOutageBypass() {
    const searchParams = useSearchParams();
    const [outageBypassCode, setoutageBypassCode] = useSessionStorage(
      SessionStorageKeys.outageBypassCode,
      null
    );

    const outageBypassCodeSearchParam = searchParams.get("outageBypassCode");
    if (env.outageBypassCode === outageBypassCodeSearchParam) {
        setoutageBypassCode(env.outageBypassCode);
        return true;
    }

    return env.outageBypassCode === outageBypassCode;
}

export function useOutageBypassPersistedCode() {
    const [outageBypassCode] = useSessionStorage(
      SessionStorageKeys.outageBypassCode,
      null
    );
    
    const outageBypass = useOutageBypass();
    if (outageBypass) {
        return outageBypassCode;
    }

    return undefined;
}