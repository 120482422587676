"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import { type GetMessagePageData } from "#app/_api/bol-authentication-service/get-message-page";
import {
  ErrorCard,
  type ErrorCardProps,
} from "#app/_ui/components/cards/ErrorCard";
import { useOutageBypass } from "#app/_ui/components/outbypass/use-outage-bypass";
import { logError } from "#app/lib/logger";
import { Banner } from "#ui/banner";
import { Container } from "#ui/container";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { Main } from "#ui/main";
import { cx } from "#ui/style.utils";
import { P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";

type UsePageMessageParams = { path: string };
type UsePageMessageData = GetMessagePageData[number] | null;

export function usePageMessage(
  params: UsePageMessageParams,
): UsePageMessageData {
  const outageBypass = useOutageBypass();
  const result = useQuery({
    ...allQueryKeys.bolAuthenticationService.messagePage,
    select: (data) => selectPageMessageByPath({ ...params, data }),
  });

  if (result.isError) {
    logError(result.error);
  }

  if (result.data !== undefined && result.data !== null) {
    return {
      ...result.data,
      isAvailable: outageBypass ? true : result.data.isAvailable,
    }
  }

  return null;
}

function selectPageMessageByPath({
  data,
  path,
}: UsePageMessageParams & { data: GetMessagePageData }): UsePageMessageData {
  const match = data.find((el) => el.path === path);
  if (
    match && (
      (
        typeof match.statusMessage === "string" &&
        match.statusMessage.length > 0
      ) || match.isAvailable === false
    )
  ) {
    return match;
  }
  return null;
}

export interface PageMessageAlertProps
  extends React.ComponentPropsWithoutRef<"div"> { }

export function PageMessageAlert({
  children,
  className,
  ...props
}: PageMessageAlertProps) {
  return (
    <div
      {...props}
      role="status"
      className={cx(
        "grid grid-cols-[auto,1fr] items-center gap-5px",
        "px-20px py-10px md:px-30px",
        "bg-warning-20 text-shade-100",
        "text-sm",
        className,
      )}
    >
      <Icon name="ms-warning-filled" palette="warning" />
      <span className="sr-only">Warning</span>
      <span>{children}</span>
    </div>
  );
}

export function PageMessageErrorCard({
  className,
  isAuthed = false,
  message,
  ...props
}: Omit<ErrorCardProps, "children"> & {
  isAuthed?: boolean;
  message?: string;
}) {
  return (
    <ErrorCard {...props} className={cx("w-full max-w-850px", className)}>
      {message ? (
        <P size="sm" palette="neutral-subtle" className="max-w-702px">
          {message}
        </P>
      ) : (
        <P size="sm" palette="neutral-subtle" className="max-w-702px">
          An unexpected error occurred while processing your request. Steps are
          being taken to resolve this issue. You may contact{" "}
          <LinkButton
            href={isAuthed ? "/contact-us" : "/tech-support"}
            variant="link"
            underline
          >
            Baird Online Support
          </LinkButton>{" "}
          or call 888-212-8843, Monday&ndash;Friday, 7:00 a.m to 6:00 p.m (CT)
          for assistance.
        </P>
      )}
      <div>
        {isAuthed ? (
          <LinkButton href="/">Go to dashboard</LinkButton>
        ) : (
          <LinkButton href="/sign-in">Ok, got it</LinkButton>
        )}
      </div>
    </ErrorCard>
  );
}

export function PageMessageErrorMain({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Main>
      <Banner />
      <Container className="grid justify-items-center">{children}</Container>
    </Main>
  );
}
